import React, {useEffect, useState} from 'react';
import {NavLink} from "react-router-dom";
import Logic from "../../Logic";
import {useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next"
import logo_2 from "../../images/logo/1.png"
import useWindowDimensions from "../../components/getWindowDimensions"

const Layout = ({logo}) => {
    const {width} = useWindowDimensions();
    const {t, i18n} = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    const [sideBar, setSideBar] = useState(true);

    const {toggle, toggleDarkTheme} = Logic();

    const toggleSidebar = () => {
        setSideBar(!sideBar);
    };

    // close sidebar when scrolling
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        window.onscroll = () => {
            setOffset(window.pageYOffset)
        }

    }, []);
    //console.log(offset)
    if (window.onscroll && offset > 1 && sideBar === false) {
        toggleSidebar()
    }

    const [dropdownMenu, setDropdownMenu] = useState(true)
    const toggleDropdownMenu = () => {
        setDropdownMenu(!dropdownMenu)
    }


    // color svg theme
    let bgLogo = '#1d2839'
    let contourLogo = '#ffffff'
    let contourTheme = '#ffffff'
    let strokeTheme = '#ffffff'

    // darth and light svg theme
    if (toggle) {
        contourLogo = '#ffffff'
        strokeTheme = '#ffffff'
        contourTheme = '#1d2839'
    } else {
        bgLogo = '#fbfcfd'
        contourLogo = '#000000'
        contourTheme = '#fbfcfd'
        strokeTheme = '#000000'
    }

    // for sidebar path and active padding left |
    let location = useLocation();


    return (
        <>
            {/*header*/}
            <header className="header">

                <div className="container">

                    <div className="header__items">

                        <div className={width >= 400 ? "logo" : "logo small"}>
                            <NavLink to="/">
                                <img style={{width: 155, marginTop: 15}} src={logo} alt="unicus-logo"/>
                            </NavLink>

                        </div>

                        <section className="navbar">
                            <a href="tel:+32 484 74 38 20">+32 484 74 38 20</a>
                            <div className="navbar__language-theme">
                                <div onClick={toggleDropdownMenu}
                                     className="internalization-toggle">
                                    <div className="container-language">
                                        {i18n.language === 'en' && <span>EN</span>}
                                        {i18n.language === 'fr' && <span>FR</span>}
                                        {i18n.language === 'nl' && <span>NL</span>}
                                    </div>
                                    <div

                                        className={!dropdownMenu ? "dropdown-menu" : "dropdown-menu display-none"}>
                                        <div className="dropdown-menu_check"></div>
                                        <button

                                            className={i18n.language === 'en' ? "dropdown-menu__button_top active_button" : "dropdown-menu__button_top"}
                                            onClick={() => changeLanguage("en")}>English
                                        </button>
                                        <button
                                            className={i18n.language === 'nl' ? "dropdown-menu__button_middle active_button" : "dropdown-menu__button_middle"}
                                            onClick={() => changeLanguage("nl")}>Netherlandish
                                        </button>
                                        <button
                                            className={i18n.language === 'fr' ? "dropdown-menu__button_bottom active_button" : "dropdown-menu__button_bottom"}
                                            onClick={() => changeLanguage("fr")}>French
                                        </button>
                                    </div>
                                </div>

                                <div className="sidebar__internalization-toggle">
                                    <button
                                        className={i18n.language === 'en' ? "active" : ""}
                                        onClick={() => changeLanguage("en")}>English
                                    </button>
                                    <button
                                        className={i18n.language === 'fr' ? "active" : ""}
                                        onClick={() => changeLanguage("fr")}>French
                                    </button>
                                    <button
                                        className={i18n.language === 'nl' ? "active" : ""}
                                        onClick={() => changeLanguage("nl")}>Netherlandish
                                    </button>
                                </div>

                                <div
                                    onClick={toggleDarkTheme}
                                    className="theme-toggle">
                                    {toggle
                                        ?
                                        <div className="container-svg">
                                            <svg viewBox="0 0 32 32" width="24px"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path fill={contourTheme} stroke={strokeTheme}
                                                      d="M16 23.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM16 4.5v-1M7.868 7.868l-.707-.707M4.5 16h-1M7.868 24.132l-.707.707M16 27.5v1M24.132 24.132l.707.707M27.5 16h1M24.132 7.868l.707-.707"
                                                      strokeWidth="2" strokeLinecap="round"
                                                      strokeLinejoin="round">
                                                </path>
                                            </svg>

                                        </div>
                                        :
                                        <div className="container-svg">
                                            <svg viewBox="0 0 32 32" width="24px"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 className="w-6">
                                                <path fill={contourTheme} stroke={strokeTheme}
                                                      d="M27.083 19.081A11.504 11.504 0 0112.919 4.917h0a11.502 11.502 0 1014.164 14.164h0z"
                                                      strokeWidth="1" strokeLinecap="round"
                                                      strokeLinejoin="round">
                                                </path>
                                            </svg>
                                        </div>
                                    }
                                </div>
                            </div>

                        </section>
                    </div>

                </div>

            </header>



        </>
    );
};

export default Layout;