import React, {useState} from 'react';
import Layout from "../layout/layout";
// import Logic from "../../Logic";
import Helmet from "react-helmet";
import {useTranslation} from "react-i18next";
import {Modal, Timeline} from 'antd';
import 'antd/dist/antd.css';
import ContactForm from "../form/ContactForm";
import logo_1 from "../../images/logo/1.png"
import logo_2 from "../../images/logo/2.png"
import logo_3 from "../../images/logo/3.png"

const Home = () => {
    // const {height, width} = useWindowDimensions();
    const {t} = useTranslation();
    // const {toggle} = Logic();
    // let bg = '#86a5d7'
    // if (toggle) {
    //     bg = '#d786cf'
    // }
    // modal
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Helmet>
                <meta name="description" content="barmanbelgium description" data-react-helmet="true"/>
                <title>barmanbelgium title</title>
            </Helmet>
            <div className="background">
                <Layout logo={logo_1}/>
                <Layout logo={logo_2}/>
                <Layout logo={logo_3}/>

                <main className="main">
                    <div className="container">
                        <section className="home-top">

                        </section>
                        {/*<section className="home-top">*/}
                        {/*    <div className="home-top__items">*/}
                        {/*        <div className="home-top__item">*/}
                        {/*            <h1>{t("home.top-about__title")}</h1>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}

                        {/*    <button className="btn-to-order" onClick={showModal}>{t("form.order")}</button>*/}
                        {/*    <Modal title={t("form.title")} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>*/}
                        {/*        /!*<MyForm/>*!/*/}
                        {/*        <ContactForm/>*/}
                        {/*    </Modal>*/}
                        {/*</section>*/}



                    </div>
                </main>
                {/*<footer className="home-footer"> ©2021 Created by <a target="_blank" href="https://devcolibri.biz">Devcolibri</a>*/}
                <footer className="home-footer"><a href="mailto:info@barmanbelgium.be">info@barmanbelgium.be</a>
                </footer>
            </div>

        </>
    );
};

export default Home;