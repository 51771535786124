import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { Button } from 'antd';
import {Trans, useTranslation} from "react-i18next";

function ContactForm() {
    const {t, i18n} = useTranslation();
    const [state, handleSubmit] = useForm("mrgrdjvl");
    if (state.succeeded) {
        return <p>{t("form.thanks")}</p>;
    }
    return (
        <form style={{'display': 'flex', 'flexDirection': 'column'}} onSubmit={handleSubmit}>
            <label htmlFor="name">
                {t("form.name")}
            </label>
            <input
                id="name"
                type="name"
                name="name"
                // placeholder="your name"
            />
            <label htmlFor="email">
                {t("form.email")}
            </label>
            <input
                id="email"
                type="email"
                name="email"
                // placeholder="your email"
            />
            <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
            />
            <label htmlFor="message">
                {t("form.message")}
            </label>
            <textarea
                id="message"
                name="message"
                // placeholder="your message"
            />
            <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
            />
            <button id="btn" type="submit" disabled={state.submitting}>
                {t("form.submit")}
            </button>
        </form>
    );
}
export default ContactForm