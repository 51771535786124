import { useSelector, useDispatch } from "react-redux";
import { themeDark, themeLight } from "./store/theme/action";

const Logic = () => {
    const dispatch = useDispatch();
    const toggle = useSelector((s) => s.theme.isDark);

    const toggleDarkTheme = () => {
        if (!toggle) {
            document.body.classList.add("dark");
            dispatch(themeDark());
        } else {
            document.body.classList.remove("dark");
            dispatch(themeLight());
        }
    };


    return {
        toggle,
        toggleDarkTheme
    };
};

export default Logic;